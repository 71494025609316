import {PlantViewToggle} from '@hconnect/common/components'
import {
  PageTitle,
  CardBox,
  PageContainer,
  buttonOnBlueSx,
  mergeSxs,
  customThemeConstants
} from '@hconnect/uikit/src/lib2'
import {FileDownload} from '@mui/icons-material'
import {LoadingButton} from '@mui/lab'
import {Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {PlantsOverviewTableContainer} from '../containers/PlantsOverviewTableContainer'
import {useHierarchyDownload} from '../hooks/api/useHierarchyDownload'
import {usePlantViewParam} from '../hooks/urlParameters/useSearchParameter'

export const PlantsOverviewPage: React.FC = () => {
  const {t} = useTranslation()
  const [plantView, setPlantView] = usePlantViewParam()
  const {isLoading: downloadIsLoading, mutate: downloadOverview} = useHierarchyDownload()

  return (
    <PageContainer>
      <Box display={'flex'} flexDirection={{xs: 'column', sm: 'row'}} alignItems="center">
        <PageTitle flex={1}>{t('plantOverviewPage.title')}</PageTitle>
        <Box display="flex" mb={customThemeConstants().defaultGridSpacing}>
          <PlantViewToggle value={plantView} onChange={setPlantView} />
          <LoadingButton
            variant={'text'}
            color={'primary'}
            startIcon={<FileDownload />}
            sx={mergeSxs(buttonOnBlueSx, {ml: 1})}
            loading={downloadIsLoading}
            onClick={() => downloadOverview()}
          >
            {t('button.export')}
          </LoadingButton>
        </Box>
      </Box>
      <CardBox>
        <PlantsOverviewTableContainer plantView={plantView} />
      </CardBox>
    </PageContainer>
  )
}
